<template>
  <div class="news">
    <public-header/>
    <div class="banner main">
      <div class="container">
       <v-breadcrumbs :items="breadCrumb" class="main-breadcrumb">
         <template v-slot:divider>
            <img src="@/assets/icons/breadcrumb-devider.svg" alt="bread crumb" />
          </template>
        </v-breadcrumbs>
      </div>
    </div>
    <main>
      <div class="container news-wrapper">
        <h1 class="news-title">{{ $t('adsPage') }}</h1>
        <div class="news-list">
          <div
            class="news-list__item"
            v-for="item in newsList"
            :key="item.id"
          >
            <div class="news-time d-flex align-center">
                <span class="icon icon-time__before"></span>
                <span class="news-time__text">
                  {{ item.time }}
                </span>
              </div>
              <p class="news-content">
                {{ item.title }}
              </p>
              <div class="news-more">
                <router-link :to="'/news-more/' + item.id">
                  {{ $t('moreDetails') }}
                </router-link>
              </div>
          </div>
        </div>
        <div class="news-pagination">
          <p v-if="currentlang == 'uz'">
            {{ thisPage }} sahifa
            {{ Math.round(allPage / countList) == 0 ? 1 : Math.round(allPage / countList) }}
            dan
          </p>
          <p v-if="currentlang == 'ru'">
            Страница {{ thisPage }} из
            {{ Math.round(allPage / countList) == 0 ? 1 : Math.round(allPage / countList) }}
          </p>
          <p v-if="currentlang == 'en'">
            Page {{ thisPage }} of
            {{ Math.round(allPage / countList) == 0 ? 1 : Math.round(allPage / countList) }}
          </p>
          <div>
            <p>{{ $t('page') }}:</p>
            <input
              @change="changeCount"
              type="text"
              v-model="thisPage"
              :placeholder="thisPage">
            <button @click="oldPage" :disabled="previousPageButton">
              <span class="icon icon-arrow-old"></span>
            </button>
            <button @click="nextPage" :disabled="nextPageButton">
              <span class="icon icon-arrow-new"></span>
            </button>
          </div>
        </div>
      </div>
    </main>
    <home-footer />
  </div>
</template>

<script>
import PublicHeader from '@/components/PublicHeader';
import HomeFooter from '@/components/HomeFooter';
import Api from '@/api/Api';

export default {
  name: 'AllNews',
  components: {
    PublicHeader,
    HomeFooter,
  },
  data() {
    return {
      breadCrumb: [
        {
          text: this.$t('main'),
          disabled: false,
          link: true,
          exact: true,
          to: {
            path: '/',
          },
        },
        {
          text: this.$t('adsPage'),
          disabled: true,
          link: true,
          exact: true,
          to: {
            path: '/all-news',
          },
        },
      ],
      countList: 12,
      allPage: 0,
      thisPage: 1,
      newsList: [],
      nextPageButton: false,
      previousPageButton: true,
      currentlang: localStorage.getItem('language'),
    };
  },
  methods: {
    async getNewsData() {
      const { data } = await Api.getNewsData({ size: this.countList, page: this.thisPage });
      this.allPage = data.count;
      if (!data.previous) {
        this.previousPageButton = true;
      } else {
        this.previousPageButton = false;
      }
      if (!data.next) {
        this.nextPageButton = true;
      } else {
        this.nextPageButton = false;
      }
      this.newsList = data.results.map((item) => {
        const {
          created_at: time,
          title,
          id,
        } = item;
        return {
          id,
          title,
          time: this.dateFormating(time),
        };
      });
    },
    oldPage() {
      this.thisPage -= 1;
      this.getNewsData();
    },
    nextPage() {
      this.thisPage = Number(this.thisPage) + 1;
      this.getNewsData();
    },
    changeCount(e) {
      this.thisPage = e.target.value;
      this.getNewsData();
    },
    dateFormating(date) {
      const currentDate = new Date(date);
      const dd = String(currentDate.getDate()).padStart(2, '0');
      const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
      const yyyy = currentDate.getFullYear();
      return `${dd}.${mm}.${yyyy}`;
    },
  },
  mounted() {
    this.getNewsData();
  },
};
</script>

<style lang="scss" src="./AllNews.scss"></style>
